<template>
  <div class="not-found">
    <div class="not-found-content container">
      <img class="img-box" src="../../assets/img/404.png" alt="" />
      <div class="title">{{ LP.lang_not_found_title }}</div>
      <div class="desc" v-html="LP.lang_not_found_desc"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState({
      whiteHeader: (state) => state.header.whiteHeader
    })
  },
  mounted() {
    this.$store.commit('header/SET_WHITE_HEADER', true)
  }
}
</script>

<style lang="less" scoped>
.not-found {
  margin-top: 79px;
  padding: 71px 0 161px;
  border-top: 1px solid #ddd;
  .not-found-content {
    text-align: center;
    .title {
      margin: 24px 0 72px;
      font-size: 48px;
      font-weight: bold;
      color: #606266;
      line-height: 64px;
    }
    .desc {
      margin: 0 auto;
      max-width: 58.9%;
      text-align: left;
      font-size: 16px;
      color: #606266;
      line-height: 21px;
    }
  }
  @media screen and (max-width: 768px) {
    margin-top: 90px;
    padding: 60px 0 80px;
    .not-found-content {
      .img-box {
        max-width: 80px;
      }
      .title {
        margin: 16px 0 40px;
        font-size: 28px;
        line-height: 40px;
      }
      .desc {
        max-width: 80%;
      }
    }
  }
}
</style>
